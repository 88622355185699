




















@import '../../../styles/login.styl'
.login-img
  margin 0 30px
  width calc(60% - 60px)
.login-logo
  margin 0 33%
  max-width 33%
@media screen and (min-width: 641px)
  .login-grid
    overflow-y scroll
    grid-template-columns minmax(390px, 40%) auto
    background-image url('~assets/derbygo/login.png')
    background-size contain
@media screen and (max-width: 641px)
  .login-side
    grid-template-rows none
  .login-img
    width calc(100% - 60px)
